import { MeetingRoomApi } from '@/api/meeting/MeetingRoomApi';

export class MeetingRoomService {
    private api = new MeetingRoomApi();

    public getRoomList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getRoomList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getRoomDetail(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getRoomDetail(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}