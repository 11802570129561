
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import SelectUserDeptTeam from "@/components/meeting/SelectUserDeptTeam.vue";
import { MeetingUserService } from "@/services/meeting/MeetingUserService.ts";
import { MeetingRoomService } from "@/services/meeting/MeetingRoomService.ts";
import { MeetingBookService } from "@/services/meeting/MeetingBookService.ts";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import { Network } from "@ionic-native/network";

export default defineComponent({
  name: "AddCalendar",
  components: {
    Header,
  },
  data() {
    const router = useRouter();

    return {
      meetingService: new MeetingRoomService(),
      meetingUserService: new MeetingUserService(),
      meetingBookService: new MeetingBookService(),
      router: router,
      useRoute: useRoute(),
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["34px"],
      colorList: new Array(),
      infoParam: {
        id: "",
      },
      title: "",
      isClick: false,
      jumpSelect: true,
      roomId: "",
      roomName: "",
      startDate: "", //日期
      startTime: "", //开始时间
      endTime: "", //结束时间
      selectedStartTime: -1,
      selectedEndTime: -1,
      dateTimes: new Array(),
      capacity: 0, //会议室容量
      rowsUser: new Array(),
      showUserList: new Array(),
      name: "",
    };
  },
  created() {
    //无网络连接
    if (Network.type === "none" || Network.type === "NONE") {
      Utils.presentToastWarning("网络连接失败，请检查网络");
    }
    if (this.useRoute.params && this.useRoute.params.jumpSelect) {
      const jumpSelectParam = this.useRoute.params.jumpSelect as string;
      this.jumpSelect = jumpSelectParam == "true" ? true : false;
      this.roomId = this.useRoute.params.id as string;
      this.startDate = this.useRoute.params.startDate as string;
      this.startTime = this.useRoute.params.startTime as string;

      if (this.jumpSelect) {
        this.selectedStartTime = parseInt(
          this.useRoute.params.selectedStartTime as string
        );
        this.selectedEndTime = parseInt(
          this.useRoute.params.selectedEndTime as string
        );
        this.initDateTimes();
        this.getRoomDetail();
      } else {
        this.endTime = this.useRoute.params.endTime as string;
        this.getRoomDetail();
      }
    }
  },
  methods: {
    initPageValue() {
      const currentUser: any = SystemUtils.loginUser;
      //获取当前人信息
      const user = {
        id: currentUser.id,
        userId: currentUser.id,
        name: currentUser.name,
      };
    },
    initDateTimes(): void {
      for (let i = this.selectedStartTime; i < this.selectedEndTime; i++) {
        this.dateTimes.push(this.getDateTime(i));
      }
      //解决 不能选择20:00 的问题；如果选择的开始时间之后没有被预约过，把最后的20:00 添加到下拉，如果选择的开始时间之后有预约的则不添加最后的上限
      if (this.selectedEndTime == 24) {
        this.dateTimes.push(this.getDateTime(24));
      }
      this.endTime = this.dateTimes[0];
    },
    getDateTime(t: number): string {
      let date1 = new Date();
      date1.setHours(8 + Math.floor(t / 2));
      date1.setMinutes(t % 2 == 0 ? 0 : 30);
      return date1.getHours() + ":" + (date1.getMinutes() == 0 ? "00" : "30");
    },
    getRoomDetail(): void {
      let params = { id: this.roomId };
      this.meetingService.getRoomDetail(params).then((result: any) => {
        if (result) {
          // this.id = result.id;
          this.roomName = result.name;
          this.capacity = result.capacity;
          this.title = this.roomName + "预订";
        }
      });
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    goBack() {
      this.router.go(-1);
    },
    //选择人员
    async selectJoiner() {
      /*
      const modal = await modalController.create({
        component: SelectUserDeptTeam,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          title: "会议成员",
          data: { meetingMember: this.rowsUser },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        let that = this;
        data.data.forEach((ele: any) => {
          //用户
          let user = {
            id: "",
            mobilePhone: ele.mobilePhone,
            userName: ele.userName,
            userId: ele.userId,
            bookId: "",
            sl: ele.sl,
          };
          let list = that.rowsUser.filter((ele: any) => {
            return ele.userId == user.userId;
          });
          if (list.length == 0) {
            this.rowsUser.push(user);
          }
        });
         this.showUserList = [];
      this.showUserList = this.rowsUser;
      }*/
      let userList = new Array();
      this.rowsUser.forEach((ele: any) => {
        userList.push({
          id: ele.id,
          userId: ele.userId,
          name: ele.userName,
        });
      });

      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: userList,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        let that = this;
        data.data.forEach((ele: any) => {
          //用户
          let user = {
            id: "",
            mobilePhone: ele.mobilePhone,
            userName: ele.name,
            userId: ele.userId,
            bookId: "",
          };
          let list = that.rowsUser.filter((ele: any) => {
            return ele.userId == user.userId;
          });
          if (list.length == 0) {
            that.rowsUser.push(user);
          }
        });
        this.showUserList = [];
        this.showUserList = that.rowsUser;
      }
    },
    loadTeamUser(teamId: string): void {
      let params = { page: 1, size: 1000, teamId: teamId, queryTotal: false };
      this.meetingUserService
        .listTeamUser(params)
        .then((result: any) => {
          let rows = result.rows;
          if (rows) {
            for (let i = 0; i < rows.length; i++) {
              let user = {
                id: "",
                mobilePhone: rows[i].mobilePhone,
                userName: rows[i].userName,
                userId: rows[i].userId,
                bookId: "",
                sl: "primary",
              };
              let f = false;
              for (const i in this.rowsUser) {
                if (this.rowsUser[i].userId === user.userId) {
                  f = true;
                }
              }
              if (!f) {
                this.rowsUser.push(user);
              }
            }
          }
        })
        .catch(() => {});
    },
    saveHandler() {
      if (!this.isClick) {
        this.isClick = true;
        let meetingUsers = new Array();
        if (this.rowsUser && this.rowsUser.length > 0) {
          this.rowsUser.forEach((row) => {
            row.sl = "primary";
            meetingUsers.push(row);
          });
        }
        let that = this;
        if (meetingUsers.length > this.capacity) {
          Utils.confirmDialog(
            "确认",
            "会议成员已经超出会议室最大容纳人数，是否确认提交",
            function () {
              that.submitHandler(that, meetingUsers);
            }
          );
        } else {
          that.submitHandler(that, meetingUsers);
        }
      }
    },
    submitHandler(that: any, meetingUsers: any) {
      const currentUser: any = SystemUtils.loginUser;
      let params = {
        id: "", //主键
        name: that.name, //名称
        roomId: that.roomId, //会议室Id
        roomName: that.roomName, //会议室名称：全称
        userId: currentUser.id, //预订人Id
        userName: currentUser.name,
        status: "0", //预订状态：1取消 0 预定中，默认 0
        remark: "", //备注
        isClean: "0", //用后是否清洁
        beginTime: that.startDate + " " + that.startTime + ":00", //预约开始时间
        endTime: that.startDate + " " + that.endTime + ":00", //预订结束时间
        meetingUsers: meetingUsers,
      };
      that.meetingBookService
        .saveRoomBook(params)
        .then((res: any) => {
          that.isClick = false;
          that.goBack();
        })
        .catch((ex:any) => {
          if(ex&&ex.data&&ex.data.error){
            Utils.presentToastWarning(ex.data.error);
          }
        });
    },
  },
});
